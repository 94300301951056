import React from 'react';
import '@resources/css/tutorial.css';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const FileUploadProgressBar = ({ isLoading, progress, message }) => {
  return (
    isLoading && (
      <div className="file-upload-overlay">
        <div className="file-upload-content">
          <h3>Uploading {message}...</h3>
          <div className="progress-bar-wrapper">
            <CircularProgressbar
              value={progress}
              text={`${Math.round(progress)}%`}
              styles={buildStyles({
                textSize: '16px',
                pathColor: `rgba(62, 152, 199, ${progress / 100})`,
                textColor: '#4b9cd3',
                trailColor: '#d6d6d6',
              })}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default FileUploadProgressBar;
