import React, { useState } from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import SubHeader from '../Common/SubHeader';
import SubmitButton from '../Common/SubmitButton';
import OrderBody from './OrderBody';
import { ACCEPTED, INSPECTION_STARTED, COMPLETED } from './Statuses';

const OrderStart = () => {
    const history = useHistory();
    const params = useParams();
    const order = useSelector(state => state.order);
    const redirectPath = order.aiBased ? 'inspection/start-ai' : 'inspection/start';

    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');

    const getUserLocation = () => {
        return new Promise((resolve, reject) => {
            // Check for Permissions API support
            if (navigator.permissions && navigator.permissions.query) {
                navigator.permissions
                    .query({ name: 'geolocation' })
                    .then(result => {
                        if (result.state === 'granted' || result.state === 'prompt') {
                            // If permission is granted or prompt, get location
                            requestLocation(resolve, reject);
                        } else {
                            // Geolocation access denied by user
                            setAlertMessage('Geolocation access denied. Please enable location access.');
                            setAlertVariant('info');
                            reject(new Error('Geolocation access denied by the user.'));
                        }
                    })
                    .catch(err => {
                        // Handle Permissions API error
                        console.error('Permissions API error:', err);
                        setAlertMessage('Error checking geolocation permissions.');
                        setAlertVariant('info');
                        reject(err);
                    });
            } else {
                // Fallback to geolocation if Permissions API is not supported
                requestLocation(resolve, reject);
            }
        });
    };

    // Helper function to get geolocation with detailed error handling
    const requestLocation = (resolve, reject) => {
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    resolve({ latitude, longitude });
                },
                error => {
                    // Handle different error codes
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            console.error('User denied Geolocation');
                            setAlertMessage('Geolocation access denied. Please enable location access.');
                            setAlertVariant('info');
                            reject(new Error('User denied Geolocation'));
                            break;
                        case error.POSITION_UNAVAILABLE:
                            console.error('Location information is unavailable');
                            setAlertMessage('Location information is unavailable.');
                            setAlertVariant('info');
                            reject(new Error('Location information is unavailable'));
                            break;
                        case error.TIMEOUT:
                            console.error('The request to get user location timed out');
                            setAlertMessage('The request to get your location timed out.');
                            setAlertVariant('info');
                            reject(new Error('The request to get user location timed out. Please try again.'));
                            break;
                        default:
                            console.error('An unknown error occurred', error);
                            setAlertMessage(
                                'An unknown error occurred while retrieving location. Please call the customer support.'
                            );
                            setAlertVariant('info');
                            reject(new Error('An unknown error occurred'));
                            break;
                    }
                },
                options
            );
        } else {
            setAlertMessage('Geolocation is not supported by this browser.');
            setAlertVariant('info');
            reject(new Error('Geolocation is not supported by this browser.'));
        }
    };

    const submitHandler = () => {
        getUserLocation()
            .then(location => {
                axios
                    .post(`/orders/${params.orderId}/inspection/start`, {
                        lat: location.latitude,
                        long: location.longitude
                    })
                    .then(res => {
                        if (res.data.StatusCode === '1') {
                            history.push(redirectPath);
                        } else {
                            // Handle unexpected response or error
                            setAlertMessage('Failed to start the inspection. Please try again.');
                            setAlertVariant('info');
                        }
                    })
                    .catch(error => {
                        // Show error message on failure
                        console.error('Error getting location:', error);
                        setAlertMessage('Error fetching location or starting inspection.');
                        setAlertVariant('danger');
                    });
            })
            .catch(error => {
                console.error('Error retrieving geolocation:', error);
                setAlertMessage('Unable to get your location. Please check your device settings.');
                setAlertVariant('info');
            });
    };

    const closeAlert = () => {
        setAlertMessage('');
        setAlertVariant('');
    };

    React.useEffect(() => {
        if (order.orderStatus === COMPLETED) {
            return history.push('completed');
        }
        if (order.orderStatus !== ACCEPTED && order.orderStatus !== INSPECTION_STARTED) {
            history.push('start');
        }
    }, [order.orderStatus]);

    return (
        <>
            <SubHeader showPageCount={false} />
            <Container className="py-4 pt-5">
                <div className="bg-white">
                    <OrderBody
                        orderNumber={order?.orderNumber}
                        vehicleDetails={order?.vehicleDetails}
                        inspectionStart={order?.inspectionStart}
                        customerName={order?.customerName}
                        customerAddress={order?.customerAddress}
                        inspectionCompleted={order?.inspectionCompleted}
                    />
                    {alertMessage && (
                        <Alert
                            variant={alertVariant}
                            className="mb-3 d-flex justify-content-between align-items-center"
                        >
                            <span>{alertMessage}</span>
                            <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={closeAlert}
                            ></button>
                        </Alert>
                    )}
                    <div className="p-3">
                        <SubmitButton className="w-100" onClick={submitHandler}>
                            Proceed For Inspection
                        </SubmitButton>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default OrderStart;
