import axios from 'axios';
import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetSlice } from '@redux/AIRedux/aiBasedSlice';
import { Button, Container, Form } from 'react-bootstrap';
import Screen from '@pages/SelfInspection/AI/Screen/Screen';
import SubHeader from '@pages/SelfInspection/Common/SubHeader';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import FileUploadProgressBar from '@pages/SelfInspection/Common/FileUploadProgressBar';

const Bucket = () => {
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [buttonState, setButtonState] = useState({
        name: 'Loading...',
        disabled: true
    });
    const [screenList, setScreenList] = useState([]);
    const [currScreenData, setCurrScreenData] = useState({});
    const [currScreenIndex, setCurrentScreenIndex] = useState(0);
    const [validated, setValidated] = useState(false);
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [progress, setProgress] = useState(0);

    const { data, images, videos } = useSelector(state => state.aiBased);

    useEffect(() => {
        axios.get(`/orders/${params.orderId}/json-ai/buckets/${params.bucketId}/screens`).then(res => {
            if (res.data.StatusCode == 1) {
                const notFilledIndex = res.data.Result.findIndex(screen => !screen.filled);
                if (notFilledIndex === -1) {
                    return history.push('../start-ai');
                }
                setCurrentScreenIndex(notFilledIndex);
                setScreenList(res.data.Result);
            }
        });
    }, []);

    useEffect(() => {
        if (!screenList.length) {
            return;
        }

        axios
            .get(
                `/orders/${params.orderId}/json-ai/buckets/${params.bucketId}/screens/${screenList[currScreenIndex]?.id}`
            )
            .then(res => {
                if (res.data.StatusCode == 1) {
                    setCurrScreenData(res.data.Result);
                    setButtonState({
                        name: 'Next',
                        disabled: false
                    });
                    window.scrollTo(0, 0);
                }
            });
    }, [params.orderId, params.bucketId, currScreenIndex, screenList]);

    const checkFormValidity = formElement => {
        if (!formElement) {
            return false;
        }

        const valid = formElement.checkValidity();
        if (valid) {
            setValidated(false);
            return true;
        }

        setValidated(true);
        const invalidInput = formElement.querySelector('input:invalid,select:invalid');
        if (invalidInput) {
            invalidInput.focus();
        }
        return false;
    };

    const handleVideos = (name, video) => {
        const chunkSize = 1 * 1024 * 1024; // 5MB (adjust based on your requirements)
        const totalChunks = Math.ceil(video.size / chunkSize);
        const chunkProgress = 100 / totalChunks;
        let chunkNumber = 0;
        let start = 0;
        let end = chunkSize; // Initialize end with chunkSize for the first chunk
        let extension = video?.name.split('.').at(-1);
        name = name.concat(`.${extension}`);
        setMessage('Video');

        setLoading(true); // Show loader during video upload

        const uploadChunk = async (chunk, chunkNumber) => {
            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('chunkNumber', chunkNumber);
            formData.append('totalChunks', totalChunks);
            formData.append('originalname', name);

            return axios
                .post(`/orders/${params.orderId}/inspection-ai/save-video`, formData)
                .then(data => {
                    const temp = `Chunk ${chunkNumber}/${totalChunks} uploaded successfully`;
                    setStatus(temp);
                    setProgress(Number(chunkNumber * chunkProgress));
                    return { success: true, data };
                })
                .catch(error => {
                    console.error('Error uploading chunk:', error);
                    return { success: false, error };
                });
        };

        const uploadChunksWithRetry = async () => {
            let retries = 3;
            while (chunkNumber < totalChunks && retries > 0) {
                const chunk = video.slice(start, end);
                const result = await uploadChunk(chunk, chunkNumber);

                if (result.success) {
                    chunkNumber++;
                    start = end;
                    end = Math.min(start + chunkSize, video.size);
                } else {
                    retries--;
                    console.log(`Retry ${3 - retries} failed for chunk ${chunkNumber}`);
                }
            }

            if (chunkNumber < totalChunks) {
                console.error(`Failed to upload all chunks after retries.`);
            } else {
                console.log('Upload complete!');
            }
        };

        // Prepare an array of promises for all chunk uploads
        const chunkPromises = [];
        while (start < video.size) {
            const chunk = video.slice(start, end);
            chunkPromises.push(uploadChunk(chunk, chunkNumber));
            chunkNumber++;
            start = end;
            end = Math.min(start + chunkSize, video.size);
        }

        // Use Promise.all to manage concurrency and retry logic
        Promise.all(chunkPromises.map(p => p.catch(e => e)))
            .then(results => {
                const failedChunks = results.filter(result => !result.success);
                if (failedChunks.length > 0) {
                    console.error(`${failedChunks.length} chunks failed to upload.`);
                    uploadChunksWithRetry();
                } else {
                    axios
                        .post(`/orders/${params.orderId}/inspection-ai/merge`, {
                            filename: name,
                            totalChunks
                        })
                        .then(() => {
                            dispatch(resetSlice());
                            setLoading(false);
                            setMessage('');
                            if (currScreenIndex + 1 < screenList.length) {
                                return setCurrentScreenIndex(curr => curr + 1);
                            }
                            console.log('All chunks uploaded successfully.');
                            return history.push('../../start-ai');
                        });
                }
            })
            .catch(error => {
                console.error('Error during chunk upload:', error);
            });
    };

    const handleNextClick = event => {
        event.preventDefault();
        if (!checkFormValidity(event.target.closest('form'))) {
            return;
        }

        if (Object.keys(videos).length !== 0) {
            Object.entries(videos).forEach(([name, file]) => {
                handleVideos(name, file[0]);
            });
            return;
        }

        let formData = new FormData();
        formData.append('data', JSON.stringify(data));

        Object.entries(images).forEach(([name, file]) => {
            formData.append(`images-${name}`, file[0]);
        });
        setLoading(true);
        setMessage('Images');
        axios
            .post(`/orders/${params.orderId}/inspection-ai/save`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(progress);
                    }
                }
            })
            .then(res => {
                if (res.data) {
                    dispatch(resetSlice());
                    setLoading(false);
                    setMessage('');
                    if (currScreenIndex + 1 < screenList.length) {
                        return setCurrentScreenIndex(curr => curr + 1);
                    }

                    return history.push('../../start-ai');
                }
            });
    };

    const handleBackClick = () => {
        if (currScreenIndex > 0) {
            setCurrentScreenIndex(curr => curr - 1);
        } else {
            history.goBack();
        }
    };

    if (loading) {
        return <FileUploadProgressBar isLoading={loading} progress={progress} message={message}/>;
    }

    return (
        <Container>
            <SubHeader
                screenNo={currScreenIndex + 1}
                screenName={currScreenData.screen_name}
                screenCount={screenList.length}
            />
            <Form noValidate validated={validated} className="d-flex flex-column gap-4">
                <Fragment>
                    <Screen parameters={currScreenData.parameters} guidelines ={currScreenData.screen_guidelines} />
                </Fragment>
                <div className="d-flex justify-content-between">
                    <Button variant="secondary" onClick={handleBackClick}>
                        Back
                    </Button>
                    <Button disabled={buttonState.disabled} onClick={handleNextClick}>
                        Next
                    </Button>
                </div>
            </Form>
        </Container>
    );
};

export default Bucket;
