import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import InnerPageBanner from './InnerPageBanner';
import { Helmet } from 'react-helmet';
const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Privacy Policy | ECO</title>
                <meta
                    name="description"
                    content="This section pertains to the Privacy Policy of ecoinspection.in. We value the trust you place in us and your details are safe with us."
                />
                {/* <!-- Twitter Card data --/> */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@ecoinspection" />
                <meta name="twitter:title" content="Privacy Policy | ECO" />
                <meta
                    name="twitter:description"
                    content="This section pertains to the Privacy Policy of ecoinspection.in. We value the trust you place in us. "
                />
                <meta name="twitter:creator" content="ecoinspection.in/privacy-policy" />

                {/* <!-- Twitter summary card with large image must be at least 280x150px --/> */}
                <meta
                    name="twitter:image:src"
                    content="https://cdn1.droom.in/imgusr/droomEco/content/car-inspection-img.png"
                />
                <meta name="twitter:app:name:googleplay" content="ECO Inspection" />
                <meta
                    name="twitter:app:url:googleplay"
                    content="https://play.google.com/store/apps/details?id=in.droom.eco"
                />

                {/* <!-- Open Graph data --/> */}
                <meta property="og:title" content="Privacy Policy | ECO" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="ecoinspection.in/privacy-policy" />
                <meta
                    property="og:image"
                    content="https://cdn1.droom.in/imgusr/droomEco/content/car-inspection-img.png"
                />
                <meta
                    property="og:description"
                    content="This section pertains to the Privacy Policy of ecoinspection.in. We value the trust you place in us. "
                />
                <meta property="og:site_name" content="@ecoinspection" />
                <meta property="og:locale" content="en_US" />

                {/* <!-- Schema.org markup for Google+ --/> */}
                <meta itemprop="name" content="Privacy Policy | ECO" />
                <meta
                    itemprop="description"
                    content="This section pertains to the Privacy Policy of ecoinspection.in. We value the trust you place in us. "
                />
                <meta itemprop="image" content="https://cdn1.droom.in/imgusr/droomEco/content/car-inspection-img.png" />

                <link rel="canonical" href="https://ecoinspection.in/privacy-policy" />
            </Helmet>
            <InnerPageBanner heading={'Privacy Policy'} />
            <div className="service-section container mb-3">
                <div className="row">
                    <div className="col-sm-12">
                        <p>
                            <strong>
                                Last updated on: 4<sup>th</sup> December 2024
                            </strong>
                        </p>
                        <p>
                            This section pertains to the Privacy Policy of https://ecoinspection.in/ and the
                            ECOInspection mobile application (hereinafter referred to as "Website"). Through the Website
                            we provide access to users to the products and services available therein (collectively
                            referred to as "Services").{' '}
                        </p>
                        <p>
                            The Website is owned and operated by Droom Technology Limited (formerly known as Droom
                            Technology Private Limited), a company incorporated under the Companies Act, 2013 with its
                            registered office at 90/31B, First Floor, Malviya Nagar, New Delhi 100017, New Delhi, India
                            (hereinafter referred to as "Droom"/"Company"). Our Privacy Policy is incorporated as part
                            of the&nbsp;Website Terms of Use.
                        </p>
                        <p>
                            We value the trust you place in us. That's why we insist upon the highest standards for
                            secure transactions and customer information privacy. Please read this Privacy Policy and
                            our Terms of Use carefully before using our Services. By using the Website's Services, you
                            agree to the provisions of this Privacy Policy. If you do not want to be bound by our
                            Privacy Policy, please do not use our Services.
                        </p>
                        <p>
                            The term "we"/ "us" / "our" used in this document refer to Droom and "you" / "your" /
                            "yourself" refer to the users, who visit or access or use or avail any service (collectively
                            usage).{' '}
                        </p>
                        <p>
                            <strong>NOTE:</strong>
                        </p>
                        <p>
                            THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF ELECTRONIC CONTRACT IN TERMS OF
                            INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER (AS AMENDED FROM TIME TO TIME)
                            AND DOES NOT REQUIRE ANY PHYSICAL SIGNATURE OR SEAL.
                        </p>
                        <p>
                            Our privacy policy is subject to change at any time without notice. To make sure you are
                            aware of any changes, please review this policy periodically.
                        </p>
                        <p>
                            By visiting this Website/Downloading App you agree to be bound by the terms and conditions
                            of this Privacy Policy. If you do not agree please do not download, use or access our
                            Website /App.
                        </p>
                        <p>
                            By mere use of the Website/App, you expressly consent to our use and disclosure of your
                            personal information in accordance with this Privacy Policy. This Privacy Policy is
                            incorporated into and subject to the Terms of Use.
                        </p>
                        <p>
                            <strong>1. COLLECTION OF PERSONALLY IDENTIFIABLE INFORMATION AND OTHER INFORMATION</strong>
                        </p>
                        <p>
                            This Privacy Policy extends to both, users who visit the Website but do not transact
                            business on the Website and as well as users who are registered on the Website and transact
                            business on the Website. When you use our Website, we collect and store your personal
                            information which is provided by you from time to time. We may also share your Personal or
                            non-Personal information where it is required and share the same with third Parties for
                            providing you the product or service and you provide us, your unconditional consent to do
                            the same.
                        </p>
                        <p>
                            Our primary goal in doing so is to provide you a safe, efficient, smooth and customized
                            experience. This allows us to provide services and features that most likely meet your
                            needs, and to customize our Website/App to make your experience safer and easier. More
                            importantly, while doing so we collect personal information from you that we consider
                            necessary for achieving this purpose.
                        </p>
                        <p>
                            In general, you can browse the Website/App without telling us who you are or revealing any
                            personal information about yourself. Once you give us your personal information, you are not
                            anonymous to us. Where possible, we indicate which fields are required and which fields are
                            optional. You always have the option to not provide information by choosing not to use a
                            particular service or feature on the Website/App. We may automatically track certain
                            information about you based upon your behaviour on our Website/App. We use this information
                            to do internal research on our users' demographics, interests, and behaviour to better
                            understand, protect and serve our users. This information is compiled and analysed on an
                            aggregated basis. This information may include the URL that you just came from (whether this
                            URL is on our Website/App or not), which URL you next go to (whether this URL is on our
                            Website/App or not), your computer browser information, and your IP address.
                        </p>
                        <p>
                            By using the Website or the Services, you consent to collection, storage, and use of the
                            personal information you provide (including any changes thereto as provided by you or your
                            representatives, nominees, assigns) for any of the services that we offer. Users of the
                            Website agree and acknowledge that they are being made fully aware by the Website / Droom
                            about the manner and the purposes for which, the information so shared /disclosed by them
                            will be used.
                        </p>
                        <p>
                            A "cookie" is a small piece of information stored by a Web server on a Web browser so it can
                            be later read back from that browser. The acceptance of cookies is not a requirement for
                            visiting the Site. However, we would like to point out that some functionality on the Site
                            and ordering is only possible with the activation of cookies. Cookies are tiny text files
                            which identify your computer to our server as a unique user when you visit certain pages on
                            the Site and they are stored by your Internet browser on your computer's hard drive.
                            Usually, cookies contain two pieces of information: a site name and a unique user ID.
                            Cookies cannot be used alone to identify you, although they may be used to identify your IP
                            address. We use data collection devices such as "cookies" on certain pages of the
                            Website/App to help analyse our web page flow, measure promotional effectiveness, and
                            promote trust and safety.Droom respects the privacy of users and processes personal data in accordance with the Privacy Policy .
                        </p>
                        <p>
                            We also use cookies to allow you to enter your password less frequently during a session.
                            Cookies can also help us provide information that is targeted to your interests. Most
                            cookies are "session cookies," meaning that they are automatically deleted from your hard
                            drive at the end of a session. You are always free to decline our cookies if your browser
                            permits, although in that case you may not be able to use certain features on the
                            Website/App and you may be required to re-enter your password more frequently during a
                            session.
                        </p>
                        <p>
                            Additionally, you may encounter "cookies" or other similar devices on certain pages of the
                            Website/App that are placed by third parties. We do not control the use of cookies by third
                            parties.
                        </p>
                        <p>
                            If you choose to buy/ transact on the Website, we collect information about your buying/
                            transaction behaviour. When you transact with us, we collect some additional information,
                            such as a billing address, a credit/ debit card number and a credit / debit card expiration
                            date and/ or other payment instrument details and tracking information from cheques or money
                            orders.
                        </p>
                        <p>
                            If you choose to post messages on our message boards, chat rooms or other message areas or
                            leave feedback, we will collect that information you provide to us. We retain this
                            information as necessary to resolve disputes, provide customer support and troubleshoot
                            problems as permitted by law.
                        </p>
                        <p>
                            If you send us personal correspondence, such as emails or letters, or if other users or
                            third parties send us correspondence about your activities or postings on the Website/App,
                            we may collect such information into a file specific to you.
                        </p>
                        <p>
                            We collect personally identifiable information (email address, name, phone number, credit
                            card / debit card / other payment instrument details, etc.) from you when you set up a free
                            account with us. While you can browse some sections of our Website/App without being a
                            registered member, certain activities (such as placing an order) do require registration. We
                            do use your contact information to send you offers based on your previous orders and your
                            interests.
                        </p>
                        <p>
                            We will only keep your information for as long as we are either required to by law or as is
                            relevant for the purposes for which it was collected.
                        </p>

                        <p>
                            <strong>2. USE OF DEMOGRAPHIC / PROFILE DATA / YOUR INFORMATION</strong>
                        </p>
                        <p>
                            We use personal information to provide the services you request. To the extent we use your
                            personal information to market to you, we will provide you the ability to opt-out of such
                            uses. We use your personal information to resolve disputes; troubleshoot problems; help
                            promote a safe service; collect money; measure consumer interest in our products and
                            services, inform you about online and offline offers, products, services, and updates;
                            customize your experience; detect and protect us against error, fraud and other criminal
                            activity; enforce our terms and conditions; and as otherwise described to you at the time of
                            collection.
                        </p>
                        <p>
                            In our efforts to continually improve our product and service offerings, we collect and
                            analyse demographic and profile data about our users' activity on our Website/App.
                        </p>
                        <p>
                            We identify and use your IP address to help diagnose problems with our server, and to
                            administer our Website/App. Your IP address is also used to help identify you and to gather
                            broad demographic information.
                        </p>
                        <p>
                            We will occasionally ask you to complete optional online surveys. These surveys may ask you
                            for contact information and demographic information (like zip code, age, or income level).
                            We use this data to tailor your experience at our Website/App, providing you with content
                            that we think you might be interested in and to display content according to your
                            preferences.
                        </p>
                        <p>
                            <strong>3. SHARING OF PERSONAL INFORMATION</strong>
                        </p>

                        <p>
                            We may share personal information with our other corporate entities and affiliates to help
                            detect and prevent identity theft, fraud and other potentially illegal acts; correlate
                            related or multiple accounts to prevent abuse of our services; and to facilitate joint or
                            co-branded services that you request where such services are provided by more than one
                            corporate entity. Those entities and affiliates may not market to you as a result of such
                            sharing unless you explicitly opt-in.
                        </p>
                        <p>
                            We further clarify that by using our Website and / or Services available on the Website, we
                            may utilize/disclose the details shared by our users, without prejudice to any of their
                            rights, for commercial or non-commercial purpose which we in our sole discretion consider
                            necessary for our business purposes or otherwise. Further, the comments and photographs of
                            Our Users may be publicized as testimonials for promotion of the Website and/ or the
                            Services.
                        </p>
                        <p>
                            We may disclose personal information if required to do so by law or in the good faith belief
                            that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other
                            legal process. We may disclose personal information to law enforcement offices, third party
                            rights owners, or others in the good faith belief that such disclosure is reasonably
                            necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement,
                            posting or other content violates the rights of a third party; or protect the rights,
                            property or personal safety of our users or the general public.
                        </p>
                        <p>
                            We and our affiliates will share / sell some or all of your personal information with
                            another business entity should we (or our assets) plan to merge with, or be acquired by that
                            business entity, or re-organization, amalgamation, restructuring of business. Should such a
                            transaction occur that other business entity (or the new combined entity) will be required
                            to follow this privacy policy with respect to your personal information.
                        </p>

                        <p>
                            <strong>4. LINKS TO OTHER SITES</strong>
                        </p>

                        <p>
                            Our Website/App links to other websites that may collect personally identifiable information
                            about you. ECO (the "Site" and the "app") is not responsible for the privacy practices or
                            the content of those linked websites nor for any third party to whom we transfer your data
                            in accordance with our Privacy Policy.
                        </p>
                        <p>
                            <strong>5. SECURITY PRECAUTIONS</strong>
                        </p>
                        <p>
                            Our Website/App has stringent security measures in place to protect the loss, misuse, and
                            alteration of the information under our control. Whenever you change or access your account
                            information, we offer the use of a secure server. Once your information is in our possession
                            we adhere to strict security guidelines, protecting it against unauthorized access.
                        </p>
                        <p>
                            <strong>6. CHOICE/OPT-OUT</strong>
                        </p>
                        <p>
                            We provide all users with the opportunity to opt-out of receiving non-essential
                            (promotional, marketing-related) communications from us on behalf of our partners, and from
                            us in general, after setting up an account. If you have any concern about privacy or
                            grievances , please contact us at <a href="mailto:support@droom.in">support@droom.in</a> or{' '}
                            <a href="mailto:legal@droom.in">legal@droom.in</a> with a thorough description and we will
                            try to resolve the issue for you. If you want to remove your contact information from ECO
                            (the "Site" and the "app") lists and newsletters, please visit unsubscribe
                        </p>

                        <p>
                            <strong>7.DATA DELETION OF USERS </strong>
                        </p>
                        <p>
                            If any registered User requests their personal data/information or account to be deleted
                            from the Website, we honour such requests and delete the said user data from our servers.
                            The Users can send an email on the customer support id support@droom.in or on
                            legal.group1@droom.in for request of their User data from the Website and we will delete the
                            Users data within 24 hours and intimate the User on their registered email on the action
                            taken.
                        </p>

                        <p>
                            <strong>8. ADVERTISEMENTS ON ECO (THE "SITE" AND THE "APP")</strong>
                        </p>
                        <p>
                            We use third-party advertising companies to serve ads when you visit our Website/App. These
                            companies may use information (not including your name, address, email address, or telephone
                            number) about your visits to this and other websites in order to provide advertisements
                            about goods and services of interest to you.
                        </p>

                        <p>
                            <strong>9. YOUR CONSENT</strong>
                        </p>
                        <p>
                            By using the Website/App and/ or by providing your information, you consent to the
                            collection and use of the information you disclose on the Website/App in accordance with
                            this Privacy Policy, including but not limited to Your consent for sharing your information
                            as per this privacy policy.
                        </p>
                        <p>
                            If we decide to change our privacy policy, we will post those changes on this page so that
                            you are always aware of what information we collect, how we use it, and under what
                            circumstances we disclose it.
                        </p>

                        <p>
                            <strong>10. GRIEVANCE OFFICER</strong>
                        </p>
                        <p>
                            In accordance with Information Technology Act 2000 and rules made there under, the name and
                            contact details of the Grievance Officer are provided below:
                        </p>
                        <p>
                            <strong>Grievance Redressal Officer:</strong>
                            <br /> Mr. Sunil Kumar{' '}
                        </p>
                        <p>
                            Plot No. 77-A, Iffco Road, Sector 18, <br />
                            Gurgaon - 122015, Haryana -India.
                            <br />
                            Time: Mon to Sat (10:00 to 19:00)
                            <br />
                            Email: <a href="mailto:support@droom.in">support@droom.in</a>
                        </p>

                        <p>
                            <strong>
                             COMPLAINCE UNDER GDPR (FOR INTERNATIONAL TRANSACTION) (EU REGION) 
                            </strong>
                        </p>
                        <p>
                            We are committed to protecting the privacy of our users and ensuring compliance with applicable data protection laws, including the <strong>General Data Protection Regulation (GDPR) for Users located in the European Union (EU).</strong>
                        </p>
                        <p>
                            <strong>
                                GDPR Compliance
                            </strong>
                        </p>
                        <p>
                            The <strong>General Data Protection Regulation (GDPR)</strong> applies to the collection and processing of personal data of individuals in the EU. In line with GDPR, we ensure that the collection, processing, and storage of Personally Identifiable Information (PII) comply with the following principles:
                        </p>
                        <p>
                            <strong>1. Lawfulness, Fairness, and Transparency:</strong> We only collect and process data for lawful purposes, transparently informing you about the use of your data.<br/>
                            <strong>2. Purpose Limitation:</strong> Data is collected solely for the purposes described in this website and will not be used for unrelated purposes without your explicit consent.<br/>
                            <strong>3. Data Minimization:</strong> We only collect the minimum amount of personal data required to provide our services effectively.<br/>
                            <strong>4. Data Quality:</strong> We take steps to ensure your data is accurate and up to date.<br/>
                            <strong>5. Limited Storage Period:</strong> Your personal data is retained only for as long as necessary for the stated purposes or as required by law.<br/>
                            <strong>6. Information Security:</strong> We employ technical and organizational measures to protect your data against unauthorized access, loss, or misuse.<br/>
                            <strong>7. Accountability:</strong> We maintain clear processes to demonstrate our compliance with GDPR requirements.
                        </p>
                        <p>
                            <strong>
                                Your Rights Under GDPR
                            </strong>
                        </p>
                        <p>
                            If you are an individual in the EU, you have the following rights regarding your personal data:<br/>
                            • Right to Be Informed <br/>
                            • Right of Access <br/>
                            • Right to Rectification <br/>
                            • Right to Erasure <br/>
                            • Right to Restrict Processing <br/>
                            • Right to Data Portability 
                        </p>
                        <p>
                            <strong>
                                Collection of sensitive data
                            </strong>
                        </p>
                        <p>
                            We do not collect or retain sensitive personal data relating to your health, ethnic origin, religious belief, or political conviction etc at this site. In the case where we do seek to collect other sensitive data, we will do so in strict compliance with the local data privacy law.
                        </p>
                        <p>
                            <strong>
                                Why do we collect your personal data and how do we use it?
                            </strong>
                        </p>
                        <p>
                            We collect and use your personal data, in compliance with applicable laws and regulations based on one or many of the following legal basis according to contexts:<br/>
                            • we have obtained your prior consents. Please note that for this specific legal basis, you have the right to withdraw your consents at any time.<br/>
                            • the processing is necessary in connection with any services to be provided.<br/>
                            • we have a legitimate interest in carrying out the processing and that legitimate interest is not overridden by your interests, fundamental rights, or freedoms.<br/>
                            • we have to process your personal data to comply with applicable laws and regulations.
                        </p>
                        <p>
                            <strong>
                                How long do we keep your personal data?
                            </strong>
                        </p>
                        <p>
                            Your personal data are processed for the period necessary for the purposes for which they have been collected, to comply with legal and regulatory obligations and for the duration of any period necessary to establish, exercise or defend any legal rights.<br/>
                            In order to determine the most appropriate retention periods for your personal data, we have specifically considered the amount, nature and sensitivity of your personal data, the reasons for which we collected your personal data, the service you deserve and expect from us together with the applicable legal requirements.
                        </p>
                        <p>
                            <strong>
                                Protection of Children
                            </strong>
                        </p>
                        <p>
                            Droom does not intend to collect personally identifiable information from children (a child is defined as being under the age of 18) without permission of the parent or legal guardian. Children should NOT submit personally identifiable information to Droom without the explicit permission of their parent or legal guardian.<br/>
                            Where appropriate, we will instruct children not to submit personal data. If your child has submitted personal data and you would like to request that such information be removed, please contact us via our privacy mailbox <a href="mailto:support@droom.in">support@droom.in</a>. 
                        </p>
                        <p>
                            <strong>
                                Whom to Contact?
                            </strong>
                        </p>
                        <p>
                            Contact us if you Have any questions about this policy or other privacy notices on this website
                            Please reach us at <a href="mailto:support@droom.in">support@droom.in</a>.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;
